export default {
  setTreeOnboardingDataState(state, data) {
    state.onboardingData = data;
  },
  mutateTreeOnboardingDataState(state, {fieldName, data}) {
    const oldData = state.onboardingData[fieldName] || {};
    state.onboardingData = {
      ...state.onboardingData,
      [fieldName]: {
        ...oldData,
        ...data,
      },
    };
  },
  setTreeOnboardingDataLoadingState(state, loading) {
    state.onboardingDataLoading = loading;
  },
  setTreeOnboardingUpdateLoadingState(state, loading) {
    state.onboardingUpdateLoading = loading;
  },
  setTreeOnboardingSuccessMessageState(state, data) {
    state.onboardingSuccessMessage = data;
  },
};
