import {isChineseText} from '@common/utils/utils';
import sortBy from 'lodash/sortBy';

function getPrimaryName(names, filterMethod) {
  if (!names) {
    return '';
  }
  for (let name of names) {
    if (filterMethod(name.value)) {
      return name.value;
    }
  }
  return '';
}

export function getFullNameEn(firstNames, surnames) {
  let firstName = getPrimaryName(firstNames, value => !isChineseText(value));
  let surname = getPrimaryName(surnames, value => !isChineseText(value));
  return [firstName, surname].join(' ').trim();
}

export function getFullNameCn(firstNames, surnames) {
  let firstName = getPrimaryName(firstNames, value => isChineseText(value));
  let surname = getPrimaryName(surnames, value => isChineseText(value));
  return `${surname}${firstName}`;
}

export function getFullNameByLangMixed(firstNames, surnames, isChinese) {
  let firstNameEn = getPrimaryName(firstNames || [], value => !isChineseText(value));
  let surnameEn = getPrimaryName(surnames || [], value => !isChineseText(value));
  let firstNameCn = getPrimaryName(firstNames || [], value => isChineseText(value));
  let surnameCn = getPrimaryName(surnames || [], value => isChineseText(value));
  if (isChinese && surnameCn && firstNameCn) {
    return `${surnameCn}${firstNameCn}`;
  }
  if (!firstNameEn && !surnameEn && surnameCn && firstNameCn) {
    return `${surnameCn}${firstNameCn}`;
  }
  if (isChinese) {
    return [firstNameCn || firstNameEn, surnameCn || surnameEn].join(' ').trim();
  }
  return [firstNameEn || firstNameCn, surnameEn || surnameCn].join(' ').trim();
}

export function getMatchWithHighlighted(value, searchWord) {
  let reg = new RegExp(searchWord, 'ig');
  let matched = value.match(reg);
  if (matched) {
    return value.replace(reg, `<span style=" background-color: #f8e71c;">${matched[0]}</span>`);
  }
}

export function getMatchWithHighlightedWords(value, searchWords) {
  let config = [];
  for (let searchWord of searchWords) {
    if (!searchWord) {
      continue;
    }
    let reg = new RegExp(searchWord, 'ig');
    let matched = value.match(reg);
    if (matched) {
      config.push([value.indexOf(matched[0]), matched[0]]);
    }
  }
  const parts = [];
  let prevIndex = 0;
  for (let [index, matched] of sortBy(config, i => i[0])) {
    let matchedLength = matched.length;
    parts.push(value.slice(prevIndex, index));
    parts.push(`<span style=" background-color: #f8e71c;">${matched}</span>`);
    prevIndex = index + matchedLength;
  }
  if (prevIndex) {
    parts.push(value.slice(prevIndex));
  }
  return parts.join('');
}

export function getAliasMatchHighlighted(names, match, ignore) {
  ignore = ignore && ignore.length ? ignore.map(ignoreText => ignoreText.toLowerCase()) : null;
  const matched = names.map(text => {
    const lowercaseText = text.toLowerCase();
    if (ignore && ignore.some(ignoreText => ignoreText.includes(lowercaseText))) {
      return;
    }
    return getMatchWithHighlighted(text, match);
  });
  return matched.filter(word => !!word);
}

export function getPersonAliasMatchHighlighted(firstNames, surnames, matchFirstName, matchSurname, ignore) {
  const bySurnames = matchSurname
    ? getAliasMatchHighlighted(
        surnames.map(item => item.value),
        matchSurname,
        ignore
      )
    : '';
  const otherNames = firstNames.filter(item => item.type === 'other_name').map(item => item.value);
  const realFirstNames = firstNames.filter(item => item.type !== 'other_name').map(item => item.value);

  const byFirstNames = matchFirstName ? getAliasMatchHighlighted(realFirstNames, matchFirstName, ignore) : '';

  const byOtherNames =
    realFirstNames.length || surnames.length
      ? otherNames
          .map(name => {
            return getMatchWithHighlightedWords(name, [matchSurname, matchFirstName]);
          })
          .filter(name => !!name)
      : [];

  const matches = [...new Set([...byOtherNames, ...bySurnames, ...byFirstNames])].join(', ');
  return matches ? `Also known as: ${matches}` : '';
}
