import UserStorage from '@common/utils/user.storage';
import {isCypressEnv} from '@common/utils/utils-e2e';

export const disableTracking = () => {
  return isCypressEnv() || UserStorage.getImpersonatedUserID();
};
export const getRoutePageName = route => {
  return route.meta && route.meta.getRouteName ? route.meta.getRouteName(route) : route.name;
};
export const getRoutePageIdentifier = route => {
  return route.meta && route.meta.getIdentifier ? route.meta.getIdentifier(route).toString() : '';
};
export const isBotUserAgent = () => {
  if (!navigator || !navigator.userAgent) {
    return true;
  }
  const botPattern =
    'googlebot|Googlebot|yahoo|bingbot|baiduspider|yandex|yeti|yodaobot|gigabot|ia_archiver|crawler|facebookexternalhit|Bytespider|applebot|twitterbot|amazonbot|developers\\.google\\.com';
  const re = new RegExp(botPattern, 'i');
  return re.test(navigator.userAgent);
};
