<template>
  <div class="mcr-footer">
    <div class="footer-content-mobile" v-if="isMobile">
      <elastic-email-subscription-form
        :hide-lists="true"
        :show-disclaimer="false"
        :email-lists="emailLists"
        submit-label="Newsletter Sign Up"
        :before-submit="trackNewsletterSignup"
      >
        <div class="subscription-form-header sub-title" slot="header">Stay Updated</div>
      </elastic-email-subscription-form>
      <div class="footer-menu">
        <div class="footer-menu-item"><router-link :to="searchRecordsRoute">Search</router-link></div>
        <div class="footer-menu-item"><router-link :to="{name: 'services-main'}">Services</router-link></div>
        <div class="footer-menu-item"><router-link :to="{name: 'contact'}">Contact</router-link></div>
        <div class="footer-menu-item"><router-link :to="{name: 'terms'}">Terms</router-link></div>
        <div class="footer-menu-item"><router-link :to="{name: 'privacy-policy'}">Privacy</router-link></div>
      </div>
      <p>{{ whatWeDo }}</p>
      <p><strong>Locations</strong> {{ locations }}</p>
    </div>
    <div class="footer-content" v-else>
      <div class="footer-info">
        <p>{{ whatWeDo }}</p>
        <p><strong>Locations</strong> {{ locations }}</p>
        <business-address-map height="200"></business-address-map>
      </div>
      <div class="footer-content-wrapper">
        <div>
          <div class="discord-block">
            <h5 class="join-discord-title">Join Us on Discord <discord-icon></discord-icon></h5>
            <mcr-button-router-link :to="{name: 'community'}">Join our Community</mcr-button-router-link>
          </div>
          <elastic-email-subscription-form
            :hide-lists="true"
            :email-lists="emailLists"
            submit-label="Sign Up"
            :before-submit="trackNewsletterSignup"
          ></elastic-email-subscription-form>
        </div>

        <div class="footer-menu">
          <div class="footer-menu-block">
            <div class="sub-title">Services</div>
            <router-link :to="{name: 'services-main'}">All Services</router-link>
            <router-link :to="{name: 'village-exploration-service'}">Village</router-link>
            <router-link :to="{name: 'adoption'}">Adoption</router-link>
            <router-link :to="{name: 'probate'}">Heirs</router-link>
            <div class="sub-title">Translation</div>
            <router-link :to="{name: 'translation'}">Translation</router-link>
            <router-link :to="{name: 'storytelling'}">Storytelling</router-link>
            <router-link :to="{name: 'zupu-translation-services'}">Zupu Translation</router-link>
            <div class="sub-title">Travel</div>
            <router-link :to="{name: 'travel'}">Roots Trips</router-link>
            <router-link :to="{name: 'film'}">Legacy Film</router-link>
          </div>
          <div class="footer-menu-block">
            <div class="sub-title">Search</div>
            <router-link :to="{name: 'subscription-plans'}">Memberships</router-link>
            <router-link :to="{name: 'institutions-indexing'}">Partnerships</router-link>
            <router-link :to="searchRecordsRoute">All Records</router-link>
            <router-link :to="{name: 'search-sources'}">All Sources</router-link>
            <router-link :to="{name: 'search-tool-surname'}">Surnames</router-link>
            <router-link :to="{name: 'search-tool-village'}">Villages</router-link>
            <!--<router-link :to="{name: 'search-tool-zupu'}">Zupus</router-link>-->
            <div class="sub-title">My Family</div>
            <router-link :to="ftRoute">Family Tree</router-link>
            <router-link :to="{name: 'my-zupus'}">My Zupus</router-link>
            <router-link :to="libraryRoute">Photos</router-link>
            <div class="sub-title">Resources</div>
            <a href="https://www.mychinaroots.com/blog">Blog</a>
            <router-link :to="{name: 'events'}">Events</router-link>
            <router-link :to="{name: 'wiki-index'}">Wiki</router-link>
          </div>
          <div class="footer-menu-block">
            <div class="sub-title">About</div>
            <router-link :to="{name: 'our-story'}">Our Story</router-link>
            <router-link :to="{name: 'testimonials'}">Testimonials</router-link>
            <router-link :to="{name: 'our-team'}">Team</router-link>
            <router-link :to="{name: 'careers'}">Careers</router-link>
            <router-link :to="{name: 'press'}">Press</router-link>
            <router-link :to="{name: 'contact'}">Contact</router-link>

            <div class="sub-title">For Libraries</div>
            <a :href="librariesSiteUrl">Subscribe</a>
            <router-link :to="{name: 'institutions-indexing'}">Digitize Records</router-link>

            <div class="sub-title">Policies</div>
            <router-link :to="{name: 'terms'}">Terms</router-link>
            <router-link :to="{name: 'privacy-policy'}">Privacy</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getFamilyTreeLibraryRoute, getFamilyTreeRoute} from '@common/utils/utils.routes';
import DiscordIcon from 'vue-material-design-icons/Discord';

import ElasticEmailSubscriptionForm from '@/components/common/forms/ElasticEmailSubscriptionForm';
import BusinessAddressMap from '@/components/common/maps/BusinessAddressMap';

export default {
  computed: {
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.tablet;
    },
    whatWeDo() {
      return 'We help people of Chinese heritage find records of their ancestors, connect with long-lost relatives, and organize roots trips to China.';
    },
    locations() {
      return 'Singapore, Guangzhou, San Francisco, London';
    },
    librariesSiteUrl() {
      return process.env.VUE_APP_LIBRARY_SITE_BASE;
    },
    ftRoute() {
      return getFamilyTreeRoute(this.$store);
    },
    libraryRoute() {
      return getFamilyTreeLibraryRoute(this.$store);
    },
    searchRecordsRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS}};
    },
    emailLists() {
      return [
        {list_public_id: 'b23a45cd-bb0f-4d9e-9872-7178dfe1a8a6', name: 'MCR Newsletter'},
        {list_public_id: '45ab1313-55dc-4637-beff-718b57e0c3b1', name: 'Stories & Search Tips'},
        {list_public_id: 'b0b33846-5f82-486f-a4e7-b0a914b55b64', name: 'New Features & Records'},
        {list_public_id: 'ca6e9d8c-9c8a-4b16-8429-d154242a90db', name: 'Offers & Discounts'},
        {list_public_id: '14e739eb-b1a2-4099-a8ad-d5ece7952023', name: 'Events & Workshops'},
      ];
    },
  },
  methods: {
    trackNewsletterSignup() {
      AnalyticsAmplitudeHandler.newsletterSignup(getRoutePageName(this.$route));
    },
  },
  components: {McrButtonRouterLink, ElasticEmailSubscriptionForm, BusinessAddressMap, DiscordIcon},
};
</script>

<style lang="scss" scoped>
@import '@common/style/mixins';

.mcr-footer {
  background-color: $dark-background-color;
  @include overlayGradient(180deg, rgba(black, 0.5), rgba(0, 0, 0, 0) 15px);
  position: relative;
  padding: 50px;

  .footer-content,
  .footer-content-mobile {
    color: $dark-text-color;

    h1,
    h4,
    h5,
    a:not(.mcr-button) {
      color: $dark-link-color;
    }
    .sub-title {
      color: $dark-supplemental-text-color;
      &:not(:first-of-type) {
        margin-top: 16px;
      }
    }
    strong {
      font-weight: normal;
      color: $dark-supplemental-text-color;
    }
  }

  .footer-content {
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .footer-content-wrapper {
      display: flex;
    }

    > *,
    .footer-content-wrapper > * {
      margin-right: 48px;
      &:last-child {
        margin-right: 0;
      }
    }
    .discord-block {
      margin-bottom: 30px;
      max-width: 370px;
      .join-discord-title {
        display: flex;
        align-items: center;

        .discord-icon {
          margin-left: 10px;
        }
      }
    }

    .footer-menu {
      display: flex;
      margin-left: 12px;

      .footer-menu-block {
        margin-right: 64px;
        min-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }

      a:not(.discord-join-button) {
        display: block;
      }
    }

    .footer-info {
      max-width: $form-max-width;
      min-width: 250px;
      p {
        strong {
          display: block;
        }
      }
    }

    @media only screen and (max-width: 1220px) {
      flex-direction: column-reverse;
      .footer-info {
        margin-top: 32px;
        margin-right: 0;
        border-top: 1px solid $dark-divider-line;
        padding-top: 24px;
        max-width: none;
        p strong {
          display: inline;
          &::after {
            content: ':';
          }
        }
      }
    }
  }

  .footer-content-mobile {
    .subscription-form-header {
      margin-bottom: 10px;
    }

    p strong {
      display: block;
    }

    .footer-menu {
      border-bottom: 1px solid $dark-divider-line;
      margin-bottom: 30px;
      padding-bottom: 20px;

      .footer-menu-item {
        margin-bottom: 15px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-mobile-se) {
    padding: 24px $mobile-se-content-padding;
  }
}
</style>
