import {getAsset} from '@common/utils/utils';

let huihanLie = {
  name: 'Huihan LIE',
  imgSrc: getAsset('/assets/about/team/huihan-lie.jpeg'),
  title: 'Founder-CEO',
  description:
    'I was born in the Netherlands, where I grew up in the countryside. My parents were born in Indonesia and had moved to the Netherlands when they were young. Apart from my name, long-life noodles',
  details_html: `
  <p>“I was born in the Netherlands, where I grew up in the countryside. My parents were born in Indonesia and had moved to the Netherlands when they were young. Apart from my name, long-life noodles on birthdays, and the color of my skin, there was nothing Chinese about my upbringing.</p>
  <p>I knew I ‘was Chinese’, but it didn’t mean anything to me. No one at home could speak the language and I didn’t know anyone in China.</p>
  <p>During my time at university, the mystery of China turned into fascination and attraction. Filled with curiosity, I went on a long-awaited blind date with China in 2004 and was quickly seduced by her opportunities and developments. I decided to stay.</p>
  <p>Some years later, while busy building a career, I started to wonder: “Where in China is our family actually from and why did they leave? How is ‘my China’ different from my ancestors’ China?” A meeting with a distant cousin in Xiamen, who knew about one of my ancestral villages, signaled the start of an immensely rewarding search for my roots, a journey that taught me about my family, our history, and myself.</p>
  <p>Our roots are not the past. They are an inseparable part of ourselves, our parents, and our children, whether or not we choose to give them any thought.</p>
  <p>Our restless world is one of imposing impressions and information running wild. Our time is one of a thousand choices and short attention spans. Walking around in my ancestral villages in Fujian and Jinmen, on the same sandy paths that six generations ago my ancestors walked, standing in front of the same ancestral altar where my ancestors stood. Knowing, experiencing. It gave me a feeling of being at ease and being connected: connected with history, with the world today, and most of all, connected with myself.</p>
  <p>It was an intensely personal and peaceful experience that deserves to be shared.”</p>

  <p>Before starting My China Roots, Huihan worked at a leading global wind energy company as Vice President of Government Affairs and External Relations. Prior to that, he was a business strategy and government relations consultant. Huihan holds a Masters Degree in International Law, having received education and training at Columbia University, the University of Amsterdam, the Netherlands Institute of International Relations “Clingendael,” the Delegation of the European Union in Beijing, and the Office of the United Nations High Commissioner for Refugees in Geneva. He speaks Dutch, English, and Chinese, and basic French and German.</p>
  `,
};

let miaoHai = {
  name: 'HAI Miao / 海淼',
  imgSrc: getAsset('/assets/about/team/haimiao.jpg'),
  title: 'General Manager',
  description:
    'As a historian with extensive experience in genealogical research, Hai Miao can be found collecting data from Beijing’s national library, tracing overseas customers’ family members in Shanghai, preserving oral histories from elderly villagers in Fujian or going through zupus at ancestral temples in Guangdong.',
  details_html: `
  <p>As a historian with extensive experience in genealogical research, Hai Miao can be found collecting data from Beijing’s national library, tracing overseas customers’ family members in Shanghai, preserving oral histories from elderly villagers in Fujian or going through zupus at ancestral temples in Guangdong.</p>
  <p>“I grew up in a small town in Liaoning province, in the northeast of China. When I was young, I always asked my grandfather to tell me stories about our family history, about our forefathers, about how my grandfather grew up in Inner Mongolia, how they lost the land they owned and how they migrated to Liaoning, having to start a new life with absolutely nothing. It made me feel connected with my forefathers, their lives and their decisions, and I came to understand them in the context of China’s rich and turbulent history. My family history is so much more than general history, it’s my history. It’s a part of me, forever. I feel privileged that, through My China Roots, I can help others find that same feeling of enrichment.”</p>
  <p>In his spare time, Hai Miao’s creative energy drives him to write film scripts, travel, and fix computers and mobile phones. During his time at China’s Inner Mongolia University, he also took up literature and philosophy in addition to his history studies. Hai speaks Chinese (native) and English, and reads both simplified and traditional Chinese.</p>
  `,
};

let jalloTang = {
  name: 'Jallo TANG / 联席董事',
  imgSrc: getAsset('/assets/about/team/jallo-tang.jpg'),
  title: 'Associate Director',
  description:
    'Jallo has been highly involved with My China Roots since our earliest days. Today, he is an angel investor and our Associate Director. Jallo is also a successful football journalist, known for interviewing and helping many Chinese Overseas football players research their roots.',
  details_html: `
  <p>Jallo has been highly involved with My China Roots since our earliest days. Today, he is an angel investor and our Associate Director. Jallo is also a successful football journalist, known for interviewing and helping many Chinese Overseas football players research their roots.</p>
  `,
};

let clotildeYap = {
  name: 'Clotilde Mei Ling YAP / 葉美玲',
  imgSrc: getAsset('/assets/about/team/Clotilde-Yap-edit.jpg'),
  title: 'Global Collections Director',
  description:
    'Clotilde was born and raised in London to a French mother and Malaysian-Chinese father. She has a passion for languages and anything that makes a good story, in particular old family legends. On her French side, her uncle has spent years documenting the life and exploits of a royalist ancestor who was beheaded during the French Revolution, and is now the pride of the entire family. On her Malaysian side, she has grown up hearing countless stories of her great-grandfather, a stowaway orphan from Fujian province who, after arriving in Malaysia, went from selling scraps and bicycle parts to starting his own construction business, in the process rubbing shoulders with the then Sultan of Selangor.',
  details_html: `
  <p>Clotilde was born and raised in London to a French mother and Malaysian-Chinese father. She has a passion for languages and anything that makes a good story, in particular old family legends. On her French side, her uncle has spent years documenting the life and exploits of a royalist ancestor who was beheaded during the French Revolution, and is now the pride of the entire family. On her Malaysian side, she has grown up hearing countless stories of her great-grandfather, a stowaway orphan from Fujian province who, after arriving in Malaysia, went from selling scraps and bicycle parts to starting his own construction business, in the process rubbing shoulders with the then Sultan of Selangor.</p>
  <p>However, it wasn’t until after studying Mandarin for several years and visiting her ancestral village in China that Clotilde (re)discovered her Chinese roots and her passion for family history. Working for My China Roots, she learned that she was not alone in thinking that exploring where you come from and what that means can be a fulfilling and enriching experience, and she has refused to leave since (despite their pleading).</p>
  <p>Before working for MCR, Clotilde studied Chinese at SOAS in London. She enjoys translating Tang poetry and modern Chinese fiction. She has always been fascinated by the concept of “Chineseness” throughout China’s imperial history as well as in today’s growing Chinese diaspora. On a clear day she can be found practising her water calligraphy with the old folk on the Olympic Green or starting her own mah-jong club.</p>
  `,
};

let pinWang = {
  name: 'Pin WANG / 王品',
  imgSrc: getAsset('/assets/about/team/Pin-Wang-headshot-cropped.jpg'),
  title: 'Product Lead',
  description:
    'How can the modern brushes of digital media bring our ancestries to life? This exciting question inspires Pin’s role as Product Lead at My China Roots. With over 10 years of experience consulting startups and Fortune 500 companies, Pin brings deep commitment and expertise to translate big-picture vision into intuitive online tools for Chinese Overseas to share their stories for generations to come.',
  details_html: `
  <p>How can the modern brushes of digital media bring our ancestries to life? This exciting question inspires Pin’s role as Product Lead at My China Roots. With over 10 years of experience consulting startups and Fortune 500 companies, Pin brings deep commitment and expertise to translate big-picture vision into intuitive online tools for Chinese Overseas to share their stories for generations to come.</p>
  <p>Born in Liaoning, Pin moved to Canada at a young age, eventually relocating to the US for high school and university. As a history major at George Mason University, he experimented (pre-Twitter!) with digital storytelling at GMU’s Center for History & New Media, then joined the games industry in Silicon Valley.</p>
  <p>Pin’s roots journey kicked off in 2009 when he moved to Beijing to study Chinese at Beijing Language and Culture University. He quickly joined the growing startup scene, where he founded a gaming company, worked for multiple startups as a product manager… and eventually met a certain (Dutch Indonesian Chinese) history buff who rekindled his passion for demystifying history’s real impact on the present. The rest, as they say, is history!</p>
  <p>When he’s not asking hard-hitting UI/UX questions, you can find Pin training in muay thai, playing the piano, or pickling every fruit and vegetable under the sun.</p>
  `,
};

let yingyingHung = {
  name: 'HUNG Ying Ying / 洪凝影',
  imgSrc: getAsset('/assets/about/team/CCC_6842-2.jpg'),
  title: 'Custom Business Lead',
  description:
    'Born in Hong Kong, Ying has traced her ancestry to Fujian, collecting photos of her grandparents and digitizing her genealogy. She is drawn to root-seeking work because it vividly shows the intimate connections between family history and national history, even the impact of a last-resort decision upon many generations down the line. If two biological brothers grow up in different countries, each brother – and his descendants – will lead very different lives. As a researcher at My China Roots, Ying has also discovered that every family seems to have a designated person who is responsible for (re)uniting family members – and she certainly fulfills that role in her family.',
  details_html: `
  <p>Born in Hong Kong, Ying has traced her ancestry to Fujian, collecting photos of her grandparents and digitizing her genealogy. She is drawn to root-seeking work because it vividly shows the intimate connections between family history and national history, even the impact of a last-resort decision upon many generations down the line. If two biological brothers grow up in different countries, each brother – and his descendants – will lead very different lives. As a researcher at My China Roots, Ying has also discovered that every family seems to have a designated person who is responsible for (re)uniting family members – and she certainly fulfills that role in her family.</p>
  <p>Ying majored in Chinese Studies at SOAS University of London, where she focused on the history of the late Qing dynasty. A nod to her roots, she enjoys the language cultures of both Cantonese and the Minnan dialect, from popular sayings to tongue-twisters.</p>
  `,
};

let chrislynChoo = {
  name: 'Chrislyn CHOO / 朱琦恩',
  imgSrc: getAsset('/assets/about/team/Purple-flower-me-300x300.jpg'),
  title: 'Brand and Innovation Director',
  description:
    '“How can the journey of connecting with your roots nurture a deeper sense of kinship and belonging?”...',
  details_html: `
  <p>“How can the journey of connecting with your roots nurture a deeper sense of kinship and belonging?”</p>
  <p>This question inspires Chrislyn as she leads our <a href="https://www.mychinaroots.com/community">online community of ancestral detectives on Discord</a>. An Emmy-winning video producer, wordsmith, and facilitator, she documents full-circle journeys to find “home” (such as our Netflix film “Found”) and designs welcoming spaces to explore our mosaic identities together.</p>
  <p>Born in the U.S. to Malaysian Chinese parents from Penang (food capital of the cosmos!), Chrislyn is guided by the stories of bridge-builders and peacemakers in her family tree:</p>
  <ul>
    <li>How her grandfather led the oldest society of Chinese artists in Malaysia</li>
    <li>How her granduncles – a CCP rocket scientist and KMT medic – reconciled after fighting on opposite sides of the Civil War</li>
    <li>How her grandmother survived Japanese occupation in WWII: While most Malaysians feared the enemy, she learned to speak Japanese and kept her hair salon open to the wives of soldiers, who would escort her safely home after work!</li>
  </ul>
  <p>Their journeys give Chrislyn hope as she now facilitates conversations and resources for the MCR community to explore the roots of our generational trauma and resilience.</p>
  <p>When she’s not streaming on Discord or experimenting in the kitchen, you can find Chrislyn swapping life stories with a stranger on a plane, subway, or checkout line. After all, the shortest distance between two people is a story!</p>
  `,
};

let ivyChan = {
  name: 'Ivy CHAN / 陈晓燕',
  imgSrc: getAsset('/assets/about/team/Ivey-Chan-1x1.jpg'),
  title: 'Public Affairs Liaison',
  description:
    'Ivy is an experienced guide who builds bridges between East and West. A natural connector with deep cultural sensitivity, she has advised countless roots seekers, from adoptees seeking their birth parents in China, to VIPs returning to their ancestral hometowns, like the former US Ambassador to China.',
  details_html: `
  <p>Ivy is an experienced guide who builds bridges between East and West. A natural connector with deep cultural sensitivity, she has advised countless roots seekers, from adoptees seeking their birth parents in China, to VIPs returning to their ancestral hometowns, like the former US Ambassador to China.</p>
  <p>Born in Guangzhou with roots in Dongguan, Ivy finds it immensely rewarding to leverage her local and global networks for cultural heritage preservation. With over 15 years of experience working at consulates for many different countries, she forges strategic partnerships as our Public Affairs Liaison, such as with the Guangdong General Chamber of Commerce.</p>
  <p>When she’s not traveling, Ivy runs a trading company and enjoys serving as vice-president of the Hong Kong Youth Culture and Leisure Performance Association.</p>
  `,
};

let jingwenLiu = {
  name: 'LIU Jingwen / 刘静雯',
  imgSrc: getAsset('/assets/about/team/CCC_6855-2.jpg'),
  title: 'Research Manager',
  description:
    'When she’s not binge-watching anime, Jingwen loves listening to the life stories of older generations. A diligent researcher with a great memory, she enjoys uncovering the real-life drama in each family story. Her own grandmother grew up on a boat along the Pearl River, and eventually eloped with a soldier from Jiangxi against her family’s wishes. Through it all, they built a life together in Guangzhou where, two generations later, Jingwen now calls home.',
  details_html: `
  <p>When she’s not binge-watching anime, Jingwen loves listening to the life stories of older generations. A diligent researcher with a great memory, she enjoys uncovering the real-life drama in each family story. Her own grandmother grew up on a boat along the Pearl River, and eventually eloped with a soldier from Jiangxi against her family’s wishes. Through it all, they built a life together in Guangzhou where, two generations later, Jingwen now calls home.</p>
  `,
};

let natalieChumachenko = {
  name: 'Natalie CHUMACHENKO',
  imgSrc: getAsset('/assets/about/team/Natalie-Chumachenko-Photo-square.jpg'),
  title: 'Software Engineer',
  description:
    'A gifted software engineer from Ukraine, Natalie knows that teamwork makes the dream work. She finds it very rewarding to collaborate on modern solutions that translate big ideas into digital reality. Combined with her programming expertise as a Master’s graduate of Zaporizhzhya Technical University, Natalie’s team spirit makes her perfectly suited to designing our online platform to help families join forces and trace their roots together.',
  details_html: `
  <p>A gifted software engineer from Ukraine, Natalie knows that teamwork makes the dream work. She finds it very rewarding to collaborate on modern solutions that translate big ideas into digital reality. Combined with her programming expertise as a Master’s graduate of Zaporizhzhya Technical University, Natalie’s team spirit makes her perfectly suited to designing our online platform to help families join forces and trace their roots together.</p>
  <p>In her free time, Natalie enjoys teaching others how to play Japanese mahjong (Riichi) – and destroying her competition in professional tournaments!</p>
  `,
};

const kostya_exp_year = 7;
const kostya_manage_exp_year = 5;

let kostiantyn = {
  name: 'Kostiantyn CHUMACHENKO',
  imgSrc: getAsset('/assets/about/team/konstantin-chumachenko.jpg'),
  title: 'Tech Lead',
  description: `A computer and board game enthusiast, Kostiantyn loves a good puzzle and coming up with creative solutions to solve real-life people problems. Kostya graduated with a Masters of Computer Science in 2015 from the Classic Private University of Zaporizhzhia, and has experience with Python, Javascript, Java, C#, Software and Game development. After ${kostya_exp_year} years in software development and ${kostya_manage_exp_year} years of team leadership, he is the latest addition to MyChinaRoots’ power-house (and power-couple!) development team on a mission to connect Overseas Chinese descendants with their roots.`,
  details_html: `<p>A computer and board game enthusiast, Kostiantyn loves a good puzzle and coming up with creative solutions to solve real-life people problems. Kostya graduated with a Masters of Computer Science in 2015 from the Classic Private University of Zaporizhzhia, and has experience with Python, Javascript, Java, C#, Software and Game development. After ${kostya_exp_year} years in software development and ${kostya_manage_exp_year} years of team leadership, he is the latest addition to MyChinaRoots’ power-house (and power-couple!) development team on a mission to connect Overseas Chinese descendants with their roots.</p>
<p>When he isn’t coding alongside his wife Natalie, Kostya enjoys playing the guitar, drums or keyboard, belting out some Pink Floyd, learning about home improvement, and taking his drone for a spin around their home in Zaporizhzhia, Ukraine.</p>
`,
};

let zhangJiasheng = {
  name: 'ZHANG Jiasheng / 张嘉晟',
  imgSrc: getAsset('/assets/about/team/zhangjiasheng.jpg'),
  title: 'Research Officer',
  description:
    'Growing up in Guangzhou, Jiasheng always believed their family was strictly Cantonese. It wasn’t until he returned to his ancestral hometown in Meizhou that he learned about his Hakka origins, and that you could indeed be both “Hakka and Cantonese”.',
  details_html: `
  <p>Growing up in Guangzhou, Jiasheng always believed their family was strictly Cantonese. It wasn’t until he returned to his ancestral hometown in Meizhou that he learned about his Hakka origins, and that you could indeed be both “Hakka and Cantonese”. </p>
  <p>When he isn’t scouring the Chinese countryside for clues, Jiasheng enjoys travelling for pleasure, playing football, and sampling the best Hakka dishes, such as Yong Tau Foo and Salted Chicken. </p>
  `,
};

let shenYushi = {
  name: 'SHEN Yushi /  沈宇诗',
  imgSrc: getAsset('/assets/about/team/yushi.jpg'),
  title: 'Research Officer',
  description:
    "Yushi was born in the same place as her mother: a small town in western Guangdong. Early in her childhood, Yushi moved to Guangzhou as her parents started a fashion business there. Her stay didn't last too long, and she returned to her hometown to finish high school. She studied Classical Literature at university in Xi'an before heading to Shanghai University for a masters in Anthropology. All in all, she lived in many places in her childhood, and never felt settled.",
  details_html: `
  <p>Yushi was born in the same place as her mother: a small town in western Guangdong. Early in her childhood, Yushi moved to Guangzhou as her parents started a fashion business there. Her stay didn't last too long, and she returned to her hometown to finish high school. She studied Classical Literature at university in Xi'an before heading to Shanghai University for a masters in Anthropology. All in all, she lived in many places in her childhood, and never felt settled.</p>
  <p>On her father's side, Yushi's family is well connected, living together in a hamlet where a heavy genealogy book resides in a newly renovated ancestral hall. The genealogy holds her name, along with her relationship to everyone in the hamlet. Her mother's family, on the other hand, is widely scattered across China. However, Yushi loves to listen to her mother's father talk about where they came from, and how her great-grandfather used to be the best lantern maker in town.</p>
  <p>Yushi enjoys studying historical materials at her desk, as well as chatting with locals to uncover stories of places and families. Fortunately, this fits right in with her work at My China Roots. With the help of the team at My China Roots, she hopes to discover the stories of her family, and would love to help others do the same!</p>
  `,
};

let liYonghui = {
  name: 'LI Yonghui / 李勇慧',
  imgSrc: getAsset('/assets/about/team/yonghui.jpg'),
  title: 'Indexer',
  description:
    'Yonghui comes from a small village in Zhaoqing, Guangdong. Although her family no longer uses genealogy to record her family history, they do use generation names (from the zupu) to name their children.',
  details_html: `
  <p>Yonghui comes from a small village in Zhaoqing, Guangdong. Although her family no longer uses genealogy to record her family history, they do use generation names (from the zupu) to name their children. She is the only girl in her generation, so her parents used the boy-name “Yong”(“勇”) from her generation.</p>
  <p>Yonghui believes that even a small genealogy book can be magical, because it may tell the story of the lives of countless people. When she found My China Roots, she was curious about how the company helped people find their ancestors and how they would record the discoveries made. After becoming a member of the indexing team, she now enjoys the pleasant feeling of making records available to the people it can help.</p>
  `,
};

let maoLiqi = {
  name: 'MAO Liqi / 毛立奇',
  imgSrc: getAsset('/assets/about/team/sky.jpg'),
  title: 'Sales Officer',
  description:
    'Liqi, known by his friends and colleagues as Sky, was born and raised in Guangzhou without knowing anything about his Hakka ancestry.',
  details_html: `
  <p>Liqi, known by his friends and colleagues as Sky, was born and raised in Guangzhou without knowing anything about his Hakka ancestry. Growing up in a city culture, he never saw a zupu or had the experience of root tracing. But since he was a child, his parents took him to grave sweeping yearly for Qing Ming festival, impressing on him a deep respect for his ancestors.</p>
  <p>Sky had fresh experiences while studying abroad in America and discovering new overseas Chinese cultures. Now that he is back in China, starting with a new beginning at My China Roots, a new fire of curiosity is lit in him. He hopes to take that same respect for his ancestors that he learned from his parents and use it to help others discover the stories of their families in China.</p>
  `,
};

let albertCheng = {
  name: 'Albert Cheng / 郑国和',
  imgSrc: getAsset('/assets/about/team/Albert-Cheng.jpg'),
  title: 'Advisor',
  description:
    'Albert Cheng 鄭國和, a mentor and advisor to Friends of Roots 尋根之友, is a fourth generation American of Chinese descent, renowned for his 40+ years of community involvement. In 1988, at the age of 40, he traveled to China for the first time, searching for, finding, and visiting his ancestral village of Sanxiang Wushi 三鄉烏石村, a hamlet in the Zhongshan County 中山縣 of Guangdong Province 廣東省 where the Cheng family 鄭族has lived for more than 26 generations since 1044. The powerful emotional journey motivated Cheng to merge his passion for genealogy, love of education, and strong belief in the importance of family history as an aspect of identity.',
  details_html: `
  <p>Albert Cheng 鄭國和, a mentor and advisor to <a href="http://www.friendsofroots.org/" target="_blank">Friends of Roots 尋根之友</a>, is a fourth generation American of Chinese descent, renowned for his 40+ years of community involvement. In 1988, at the age of 40, he traveled to China for the first time, searching for, finding, and visiting his ancestral village of Sanxiang Wushi 三鄉烏石村, a hamlet in the Zhongshan County 中山縣 of Guangdong Province 廣東省 where the Cheng family 鄭族has lived for more than 26 generations since 1044. The powerful emotional journey motivated Cheng to merge his passion for genealogy, love of education, and strong belief in the importance of family history as an aspect of identity.</p>
  <p>Soon after in 1989, he and the late renowned Chinese American historian Him Mark Lai 麥禮謙 spearheaded and participated in a family history and genealogy conference sponsored by the Chinese Historical Society of America (CHSA) 美國華人歷史學會 and Chinese Culture Foundation of San Francisco (CCF) 舊金山中華文化基金會. From that conference came the idea for a program to guide Chinese American young people to research their roots in America and visit their ancestral villages in Guangdong Province, China. In 1991, they founded and became the coordinators of the In Search of Roots program, which is co-sponsored by the CHSA, CCF, and the Overseas Chinese Affairs Office of Guangdong Province 廣東省人民政府橋務辦公室.</p>
  <p>For 28 years, Cheng has led hundreds of Chinese Americans back to China to search for and visit their ancestral villages. In 2001, the Overseas Chinese Affairs Office of Guangdong Province awarded Cheng the title, “Searching for Chinese Roots Pioneer” 尋根引路者. In 2006, KQED Public Television recognized him as a local San Francisco Bay Area hero. In 2011, Mayor Ed Lee and the Board of Supervisors of the City and County of San Francisco, declared April 9 as Albert Cheng Day.</p>
  <p>Cheng was featured in a national 2012 PBS documentary, “Searching for Roots in Canton,” produced by D3 Media Group.</p>
  <p>In 2012, Cheng received the “Grand Charity/Humanitarian Award 2012 南方·华人慈善盛典” from the Overseas Chinese Affairs Office of Guangdong Province, China. He is also endearingly referred to as “寻根之父,” the father of searching for Chinese roots.</p>
  <p>In 2016, the Angel Island Immigration Station Foundation awarded Cheng the Immigrant Heritage Community Leader Award.</p>
  <p>Today, Cheng continues to volunteer for the Friends of Roots Him Mark Lai Family History Project 麥禮謙尋根項目 and lead several groups back to China to search for and visit their ancestral villages.</p>
  <p>As an educator of over 30 years, Cheng believes that strong roots build strong character, strong character creates strong communities, and strong communities give us a strong country… subsequently the world will be a more peaceful place. He is guided by the Chinese saying, 飲水思源 yinshui siyuan, “when drinking water, remember the source.”</p>
  `,
};

let dannyChin = {
  name: 'Danny CHIN / 钱正民',
  imgSrc: getAsset('/assets/about/team/danny120x120-1-300x300.png'),
  title: 'Advisor',
  description:
    'Danny has over 20 years of experience in family history and genealogy at FamilySearch, the world’s largest genealogy organization. As Area Fields Relations Manager, he has led FamilySearch’s strategy, research, and institutional partnerships in Asia. Now a hands-­on part of our team, Danny is helping My China Roots grow our database of Chinese genealogical sources and develop valuable relationships with libraries, book collectors, museums and archives inside and outside of China.',
  details_html: `
  <p>Danny has over 20 years of experience in family history and genealogy at FamilySearch, the world’s largest genealogy organization. As Area Fields Relations Manager, he has led FamilySearch’s strategy, research, and institutional partnerships in Asia. Now a hands-­on part of our team, Danny is helping My China Roots grow our database of Chinese genealogical sources and develop valuable relationships with libraries, book collectors, museums and archives inside and outside of China.</p>
  `,
};

let kendallHulet = {
  name: 'Kendall HULET',
  imgSrc: getAsset('/assets/about/team/kendall.jpeg'),
  title: 'Advisor',
  description:
    'Kendall is a former Senior Vice President at Ancestry.com, where he worked for over 14 years. Kendall had a leading role in defining, launching, and managing several of Ancestry’s pillars of success, including ancestryDNA and the groundbreaking record-hints system. Kendall’s experience and central role in building the world’s largest genealogy and consumer genomics company is invaluable for My China Roots’ ambitions. Other than advising and guiding us through the pitfalls that come with a fast growing genealogy company, Kendall functions as CEO of Cake Technologies.',
  details_html: `
  <p>Kendall is a former Senior Vice President at Ancestry.com, where he worked for over 14 years. Kendall had a leading role in defining, launching, and managing several of Ancestry’s pillars of success, including ancestryDNA and the groundbreaking record-hints system. Kendall’s experience and central role in building the world’s largest genealogy and consumer genomics company is invaluable for My China Roots’ ambitions. Other than advising and guiding us through the pitfalls that come with a fast growing genealogy company, Kendall functions as CEO of Cake Technologies.</p>
  `,
};

let richardRobinson = {
  name: 'Richard ROBINSON',
  imgSrc: getAsset('/assets/about/team/rich-300x300.jpeg'),
  title: 'Advisor',
  description:
    'Richard is a seasoned investor, entrepreneur, and advisor with 20+ years of experience in China. He has led 8 successive startups as a co-founder or senior executive (e.g. nHack, Yolu, Shouji Mobile Entertainment), plus dozens more as an investor, board member, advisor or mentor (HAX, Chinaccelerator & 500). He now advises companies on entering China, teaches entrepreneurship at Peking University’s Guanghua MBA program, and produces stand-up comedy shows across Asia.',
  details_html: `
  <p>Richard is a seasoned investor, entrepreneur, and advisor with 20+ years of experience in China. He has led 8 successive startups as a co-founder or senior executive (e.g. nHack, Yolu, Shouji Mobile Entertainment), plus dozens more as an investor, board member, advisor or mentor (HAX, Chinaccelerator & 500). He now advises companies on entering China, teaches entrepreneurship at Peking University’s Guanghua MBA program, and produces stand-up comedy shows across Asia.</p>
  `,
};

let jiayiChen = {
  name: 'CHEN Jiayi / 陈嘉怡',
  imgSrc: getAsset('/assets/about/team/chenjiayi.jpg'),
  title: 'Indexing Lead',
  description:
    'Jiayi was born and grew up in a small village in Foshan, Guangdong Province, which has been her family’s home since her great-grandfather’s generation. Until working for My China Roots, she never questioned where her family came from. A meticulous worker and Administration Major from Sun Yat-sen University, Jiayi spends most of her days poring over ancient books such as zupus and gazetteers, immersing herself in the lives of people of another era. As the head archivist at My China Roots’ Guangzhou office, Jiayi is proud that she can help others discover and enrich their family histories by preserving their stories and making them more accessible.',
  details_html: `<p>Jiayi was born and grew up in a small village in Foshan, Guangdong Province, which has been her family’s home since her great-grandfather’s generation. Until working for My China Roots, she never questioned where her family came from. A meticulous worker and Administration Major from Sun Yat-sen University, Jiayi spends most of her days poring over ancient books such as zupus and gazetteers, immersing herself in the lives of people of another era. As the head archivist at My China Roots’ Guangzhou office, Jiayi is proud that she can help others discover and enrich their family histories by preserving their stories and making them more accessible. </p>
<p>Outside of work, she can be found curled up at home with her fluffy cat Yuanlai, reading online novels or watching variety shows.</p>`,
};

export default {
  teamMembers: [
    huihanLie,
    miaoHai,
    jalloTang,
    clotildeYap,
    pinWang,
    yingyingHung,
    natalieChumachenko,
    kostiantyn,
    chrislynChoo,
    jingwenLiu,
    jiayiChen,
    ivyChan,
    zhangJiasheng,
    shenYushi,
    liYonghui,
    maoLiqi,
  ],
  advisorMembers: [albertCheng, dannyChin, kendallHulet, richardRobinson],
  researchMembers: [jingwenLiu, zhangJiasheng, shenYushi],
};
